// Layout.js
import React from 'react';
import FooterNav from './FooterNav';
import BrandBar from './BrandBar';

function Layout({ children }) {
    return (
        <div className="app-container">
          <BrandBar />
            <main>
                {children}
            </main>
          <FooterNav />
        </div>
  );
}

export default Layout;
