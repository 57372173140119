import React, {useState} from 'react';
import EmailForm from './EmailForm';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Layout from './Layout';



function App() {
    const [userData, setUserData] = useState(
        {
            email: ""
        }
    );

    const handleFormSubmit = (formData) => {
        setUserData(formData);
    };

    return (
      <Router>
          <Layout>
              <Routes>
                  <Route path="/terms-of-service" element={<TermsOfService />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/" element={<EmailForm />} />
              </Routes>
          </Layout>
      </Router>
    );
}

export default App;
