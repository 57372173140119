// src/components/TermsOfService.js
import React from 'react';
import Section from './Section';
import ToSNavbar from "./ToSNavbar";

function TermsOfService() {
  const sections = [
      {
      id: 'section1',
      title: 'Acceptance of Terms',
      content: ['By subscribing to our newsletter, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not subscribe to our newsletter.']
    },
    {
      id: 'section2',
      title: 'Subscription',
      content: [
          'You may subscribe to our newsletter by providing your email address and any other required information through our subscription form.',
          'You must provide accurate and complete information when subscribing to our newsletter. You are responsible for maintaining the accuracy of your subscription information.']
    },
    {
      id: 'section3',
      title: 'Privacy and Data Usage',
      content: ['We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and safeguard your personal information. By subscribing to our newsletter, you consent to the terms of our Privacy Policy.',]
    },
    // {
    //   id: 'section4',
    //   title:'Subscription Confirmation',
    //   content: ['After subscribing, you will receive a confirmation email to the provided email address. You must confirm your subscription by following the instructions in the confirmation email. Failure to confirm your subscription may result in not receiving our newsletter.',]
    // },
    {
      id: 'section5',
      title:'Unsubscribe',
      content: ['You can unsubscribe from our newsletter at any time by clicking the "unsubscribe" link in the newsletter or by contacting us directly. Once you unsubscribe, you will no longer receive our newsletter.',]
    },
    {
      id:'section6',
      title:'Newsletter Content',
      content: ['We reserve the right to modify, suspend, or discontinue our newsletter at any time without prior notice. We are not liable for any consequences resulting from the modification, suspension, or discontinuation of our newsletter.']
    },
    {
      id:'section7',
      title:'Intellectual Property',
      content: ['All content provided in our newsletter, including text, images, and other materials, is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or use our newsletter content without our express written consent.']
    },
    {
      id:'section8',
      title:'Disclaimer',
      content: [
          'Our newsletter is provided "as is" without any warranties, expressed or implied. We do not guarantee the accuracy, timeliness, or completeness of the information provided in our newsletter.',
          'We are not liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your subscription to our newsletter.'
      ]
    },
    {
      id:'section9',
      title:'Governing Law',
      content: ['These Terms of Service are governed by and construed in accordance with the laws of New York, without regard to its conflict of law principles.']
    },
    {
      id:'section10',
      title:'Changes to Terms',
      content: ['We may revise these Terms of Service at any time. Any changes will be effective immediately upon posting on our website. Your continued subscription to our newsletter after any changes to these terms will constitute your acceptance of those changes.']
    },
    {
      id:'section11',
      title:'Contact Information',
      content: ['If you have any questions or concerns regarding these Terms of Service, you may contact us at support@sportyventures.com.']
    },
  ];
  return (
    <div>
      <div className="container-wrapper">
        <div className="policy">
          <div className="header">SportyVentures Terms of Service</div>
          <p>Last Updated: November 17, 2023</p>
          {sections.map((section) => (
              <Section
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  content={section.content}
              />
          ))}
        </div>
        <ToSNavbar sections={sections}/>
      </div>
    </div>
  );
}

export default TermsOfService;
