// ToSNavbar.js

import React from 'react';

function ToSNavbar({ sections }) {
  return (
    <nav className="tos-nav">
      <ul>
        {sections.map((section) => (
          <li key={section.id}>
            <a href={`#${section.id}`}>{section.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default ToSNavbar;
