// Section.js

import React from 'react';

function Section({ id, title, content }) {
  return (
    <div id={id} className="section">
      <h2>{title}</h2>
      <div className="content">
        {content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
}

export default Section;
