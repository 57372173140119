// BrandBar.js
import React from 'react';
import {useLocation, Link } from 'react-router-dom'; // Import Link
import logo from './assets/sv_logo_multi.png';

function BrandBar() {
    const location = useLocation();
    const shouldRenderBrandBar = location.pathname !== '/terms-of-use' && location.pathname !== '/privacy-policy';

  return (
    <div className="brand-bar">
      <Link to="/">
        <img src={logo} height="20" alt="SportyVentures Logo" />
      </Link>
    </div>
  );
}

export default BrandBar;
