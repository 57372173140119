// PrivacyNavbar.js

import React from 'react';

function PrivacyNavbar({ sections }) {
  return (
    <nav className="privacy-nav">
      <ul>
        {sections.map((section) => (
          <li key={section.id}>
            <a href={`#${section.id}`}>{section.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default PrivacyNavbar;
