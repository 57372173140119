// FooterNav.js
import React from 'react';
import './FooterNav.css'; // Import your CSS file
import { Link } from 'react-router-dom';

function FooterNav() {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/terms-of-service">Terms of Service</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;
