// src/components/PrivacyPolicy.js
import React from 'react';
import Section from "./Section";
import PrivacyNavbar from "./PrivacyNavbar";

function PrivacyPolicy() {
    const sections = [
      {
      id: 'section1',
      title: 'Information We Collect',
      content: [
          'Personal Information: When you subscribe to our newsletter, we may collect your email address and, optionally, your name or other identifying information you provide voluntarily.',
          'Automatically Collected Information:We may also collect certain information automatically, such as your IP address, browser type, and device information when you access our website.',]
      },
      {
      id: 'section2',
      title: 'Use of Information',
      content: [
          'We use the information we collect for the following purposes:',
          'Newsletter Delivery: To send you our newsletter and updates.',
          'Communication: To respond to your inquiries and provide customer support.',
          'Analytics: To analyze user engagement with our newsletter and website, improve our content, and enhance user experience.']
      },
      {
      id: 'section3',
      title: 'Protection of Information',
      content: [
          'We take reasonable measures to protect the information we collect and store, including administrative, technical, and physical safeguards. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the absolute security of your data.']
    },
    {
      id: 'section4',
      title: 'Disclosure of Information',
      content: [
          'We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except:',
          'To trusted third parties who assist us in delivering our newsletter, provided they agree to keep the information confidential.',
          'To comply with legal requirements or respond to lawful requests from government authorities.'
      ]
    },
    {
      id: 'section5',
      title: 'Your Choices',
      content: [
          'Subscription: You can subscribe to our newsletter by providing your email address. You can unsubscribe at any time by clicking the "unsubscribe" link in our newsletter or by contacting us directly.',
          'Cookies: You can manage your cookie preferences through your browser settings.']
    },
    {
      id: 'section6',
      title: 'Third-Party Links',
      content: [
          'Our newsletter may contain links to third-party websites or services. We are not responsible for the content or privacy practices of these third-party sites. We encourage you to review their privacy policies before interacting with them.',]
    },
    {
      id: 'section7',
      title: 'Children\'s Privacy',
      content: [
          'Our services are not directed to individuals under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us, and we will promptly delete such information.']
    },
    {
      id: 'section8',
      title: 'Changes to this Privacy Policy',
      content: [
          'We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated policy on our website or by sending you an email notification.',]
    },
    {
      id: 'section9',
      title: 'Contact Us',
      content: [
          'If you have any questions or concerns about this Privacy Policy or our practices, please contact us at support@sportyventures.com.',]
    }
  ];
  return (
    <div>
      <div className="container-wrapper">
        <div className="policy">
          <div className="header">SportyVentures Privacy Policy</div>
          <p>Last Updated: November 17, 2023</p>
          {sections.map((section) => (
              <Section
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  content={section.content}
              />
          ))}
        </div>
        <PrivacyNavbar sections={sections}/>
      </div>
    </div>
  );
}

export default PrivacyPolicy;